// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("./images/see_diving.jpg");
var ___HTML_LOADER_IMPORT_1___ = require("./images/partners.jpg");
var ___HTML_LOADER_IMPORT_2___ = require("./images/grad.jpg");
var ___HTML_LOADER_IMPORT_3___ = require("./images/me.jpg");
var ___HTML_LOADER_IMPORT_4___ = require("./images/colleagues1.jpg");
var ___HTML_LOADER_IMPORT_5___ = require("./images/colleagues2.jpg");
var ___HTML_LOADER_IMPORT_6___ = require("./images/colleagues3.jpg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var ___HTML_LOADER_REPLACEMENT_4___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_4___);
var ___HTML_LOADER_REPLACEMENT_5___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_5___);
var ___HTML_LOADER_REPLACEMENT_6___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_6___);
var code = "<div class=\"scene-content\"> <div id=\"photo_container\"> <div class=\"photo1\"> <div class=\"polaroid\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"/> </div> <div class=\"caption\"> <text> ¯\\_(ツ)_/¯</text> </div> </div> <div class=\"photo2\"> <div class=\"polaroid\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\"/> </div> <div class=\"caption\"> <text>NAN Team</text> </div> </div> <div class=\"photo3\"> <div class=\"polaroid\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\"/> </div> <div class=\"caption\"> <text>Graduation day!</text> </div> </div> <div class=\"photo4\"> <div class=\"polaroid\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\"/> </div> <div class=\"caption\"> <text>Bye Bye glasses!</text> </div> </div> <div class=\"photo5\"> <div class=\"polaroid\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_4___ + "\"/> </div> <div class=\"caption\"> <text>404-NOTFOUND Team</text> </div> </div> <div class=\"photo6\"> <div class=\"polaroid\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_5___ + "\"/> </div> <div class=\"caption\"> <text>Eonics Coding Challenge</text> </div> </div> <div class=\"photo7\"> <div class=\"polaroid\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_6___ + "\"/> </div> <div class=\"caption\"> <text>My partner in crime</text> </div> </div> </div> <div id=\"skills_container\"> </div> </div> <div class=\"svg\"></div> ";
// Exports
module.exports = code;