export default [
  "NoSQL",
  "Linux",
  "Competitive Programming",
  "Web Servers",
  "User-centred design",
  "AWS",
  "Docker",
  "Scalability",
  "The Double Diamond",
  "Design patterns",
  "Web Security",
  "Functional Programming",
  "POO",
  "Shadow DOM",
  "Continuous integration",
  "Java",
  "Pair programming",
  "Experience design",
  "API Design",
  "Angular",
  "Spring",
  "Application architectures",
  "Test-driven development",
  "Jest",
  "Visual regression testing",
  "RxJS",
  "Selenium",
  "MongoDB",
  "Module bundlers",
  "Performance",
  "Internet of things",
  "Multi-threading",
];
