import scene1 from "../scenes/scene1/animation";
import scene2 from "../scenes/scene2/animation";
import scene3 from "../scenes/scene3/animation";
import scene4 from "../scenes/scene4/animation";
import scene5 from "../scenes/scene5/animation";
import scene6 from "../scenes/scene6/animation";

export default () => ({
  scene1,
  scene2,
  scene3,
  scene5,
  scene4,
  scene6,
});
